<template>
  <div 
    v-if="needToDisplay"
    ref="horseRaceLabelRef"
    class="product-card__horse-race-container"
  >
    <!-- 单行模式 -->
    <ProductCardAutoWrap
      v-if="displayMode === 'singleRow'"
      ref="autoWrapRef"
      :rows="swiperLabelList"
      :high-light-row="0"
      :row-height="SLIDE_HEIGHT"
      :is-mounted="isMounted"
    >
      <ProductCardSellingPropositionRank
        :style="{
          '--selling-rank-height': `${SLIDE_HEIGHT}px`, '--selling-rank-margin-top': '0'
        }"
        :ranking-list="rankingList"
      />
    </ProductCardAutoWrap>
    
    <!-- 多行模式 -->
    <Swiper
      v-if="displayMode === 'swiper'"
      class="product-card__horse-race-swiper"
      :delay-loop-init-time="1500"
      :delay="3000"
      direction="vertical"
      intersection-observer
      @loop-play-start="handleLoopPlayStart"
    >
      <swiper-item
        v-for="(slideIndex, index) in SLIDE_ROW"
        :key="slideIndex"
        ref="swiperItemRef"
      >
        <ProductCardAutoWrap
          ref="autoWrapRef"
          :rows="swiperLabelList"
          :high-light-row="index"
          :row-height="SLIDE_HEIGHT"
          :is-mounted="isMounted"
        >
          <ProductCardSellingPropositionRank
            :style="{
              '--selling-rank-height': `${SLIDE_HEIGHT}px`, '--selling-rank-margin-top': '0'
            }"
            :ranking-list="rankingList"
          />
        </ProductCardAutoWrap>
      </swiper-item>
    </Swiper>
  </div>
</template>

<script name="ProductCardHorseRaceLabelContainer" setup>
import { template } from '@shein/common-function'

import ProductCardSellingPropositionRank from './ProductCardSellingPropositionRank.vue'
import Swiper from 'public/src/pages/components/Swiper/Swiper.vue'
import swiperItem from 'public/src/pages/components/Swiper/SwiperItem.vue'
import ProductCardAutoWrap from './ProductCardAutoWrap.vue'
import { exposeHorseRaceLabel } from '../../analysis/index'

import { ref, inject, watch, computed, onMounted } from 'vue'

// 榜单埋点拼接
const getRankingAna = (rankingList, index, isFirst) => {
  let { contentCarrierId, rankingType, carrierSubType, cateIds, score } = rankingList ?? {}

  const delimiter = isFirst ? '_' : '`'

  const cateIdsText = (cateIds || '').replace(',', '_')

  const baseAna = `${carrierSubType}${delimiter}rn=${rankingType}${delimiter}ps=${index + 1}${delimiter}jc=${contentCarrierId}${delimiter}cate_id=${cateIdsText || '-'}${delimiter}goods_rank=${score}`

  if (isFirst) {
    return `ranking_list_identifier=${baseAna}`
  } else {
    return `ri=${baseAna}`
  }
}

const goodsInfo = inject('goodsInfo', () => ({}))
const labelsFromKey = inject('labelsFromKey')
const item = inject('item', () => {})

// 被轮播标签的高度
const SLIDE_HEIGHT = 16
// 轮播的行数
const SLIDE_ROW = 2
// 多少个标签为一行
const ONE_LINE_LABEL_NUM = 2
// 最多显示标签数量
const MAX_LABEL_NUM = 4

const textDataLabel = ({ text, appTraceInfo, fontStyle, style = {}, icon = '', labelLangEn = '', index, tagId, labelId, suiIcon, isCccxHorseLabel, otherMarkAna }) => {
  return {
    type: 'text',
    random: Math.random(),
    style: style,
    textData: {
      appTraceInfo: appTraceInfo || '',
      text,
      fontStyle,
      icon,
      tagId: tagId || '',
      labelId: labelId || '',
      labelLangEn,
      suiIcon,
    },
    labelIndex: index,
    isCccxHorseLabel,
    otherMarkAna
  }
}

const getRelativeOffsetTop = (element, parentElement) => {
  if (!element || !parentElement) return
  const rect = element.getBoundingClientRect()
  const parentRect = parentElement.getBoundingClientRect()
  // 向下取整
  return ~~(rect.top - parentRect.top)
}

const getLabelHeight = (label) => {
  // 向下取整
  return ~~(label?.getBoundingClientRect().height)
}

/**
 * 处理赛马标签
 */
const handelLabelData = () => {
  const { pretreatInfo = {} } = goodsInfo.value ?? {}
  const { showQuickShip } = config ?? {}

  // 来自赛马的标签数据
  const horseRaceLabel = [...(pretreatInfo?.horseRaceLabel ?? [])]

  if (!horseRaceLabel.length) {
    return {
      swiperLabelList: [],
    }
  }

  // 赛马榜单标签 没有则在处理前删除horseRaceLabel
  const horseRaceLabelRankingIndex = (horseRaceLabel ?? []).findIndex(v => v.rankingList?.tagId)
  const { showRankingList } = config ?? {}
  const { rankingList } = pretreatInfo?.[labelsFromKey] ?? {}
  if (horseRaceLabelRankingIndex > -1 && ( !showRankingList || !rankingList )) {
    horseRaceLabel.splice(horseRaceLabelRankingIndex, 1)
  }

  const swiperLabelList = horseRaceLabel?.slice(0, MAX_LABEL_NUM).map(({ 
    realLeftStock,
    starComment,
    userBehaviorLabel, 
  }, index) => {
    // 真实库存
    const { fontColor: realLeftStockColor, labelLang: realLeftStockLang, ana } = realLeftStock || {}
    if (realLeftStock) {
      return textDataLabel(
        {
          index,
          text: realLeftStockLang,
          appTraceInfo: realLeftStock.appTraceInfo || '',
          labelId: realLeftStock.labelId,
          style: {
            color: realLeftStockColor
          },
          otherMarkAna: realLeftStock.appTraceInfo || ana
        }
      )
    }
    
    // 星级评论处理
    if (starComment) {
      return {
        type: 'star',
        random: Math.random(),
        starData: starComment,
        labelIndex: index
      }
    }

    // 用户行为标签
    const { labelLang, backgroundColor, appTraceInfo, fontColor, icon, labelLangEn, tagId, suiIcon, labelCustomType } = userBehaviorLabel || {}
    const isQuickShip = labelCustomType == 'quickship'
    const showLabel = isQuickShip ? showQuickShip : true // 是quickship标签时，需要由showQuickShip控制是否展示
    if (showLabel && labelLang) {
      return textDataLabel(
        {
          index,
          appTraceInfo,
          text: labelLang,
          fontStyle: 'golden',
          style: {
            backgroundColor: backgroundColor,
            color: fontColor
          },
          icon,
          labelLangEn,
          tagId,
          labelId: userBehaviorLabel.labelId,
          suiIcon: isQuickShip ? template('16px', suiIcon) : suiIcon,
          isCccxHorseLabel: true
        }
      )
    }
  }).filter(Boolean)
  
  return {
    swiperLabelList,
  }
}

const config = inject('config', () => {})
const goodsIndex = inject('index', 0)
const setAttrForAnalysis = inject('setAttrForAnalysis')
const swiperLabelList = ref([])
const displayMode = ref('')
const isMounted = ref(false)
const autoWrapRef = ref(null)
const needToDisplay = ref(false)
const swiperItemRef = ref(null)
const horseRaceLabelRef = ref(null)
const isInViewPort = ref(false)
const exposeSwitch = ref([])

// 排行榜数据
const rankingList = computed(() => {
  return config.showRankingList && goodsInfo.value?.pretreatInfo?.[labelsFromKey]?.rankingList
})

const switchDisplayMode = () => {
  const autoWrapElement = autoWrapRef.value?.$el
  const getHorseRaceLabels = autoWrapElement?.querySelectorAll('.product-card__auto-wrap > div:not(.placeholder)') || []


  if (getHorseRaceLabels.length === 0) {
    return
  }

  const renderFirstSlide = []
  const renderSecondSlide = []

  Array.from(getHorseRaceLabels).forEach((label, index) => {
    const labelOffsetTop = getRelativeOffsetTop(label, autoWrapElement)
    const labelHeight = getLabelHeight(label)

    const swiperLabelListFilted = swiperLabelList.value.map(item => item.type === 'gap' ? undefined : item).filter(Boolean)


    if (labelOffsetTop === 0) {
      swiperLabelListFilted[index] && renderFirstSlide.push(swiperLabelListFilted[index])
    }
    
    if (labelOffsetTop === labelHeight) {
      swiperLabelListFilted[index] && renderSecondSlide.push(swiperLabelListFilted[index])
    }
  })

  const firstSlideItems = []
  renderFirstSlide.forEach((item, index) => {
    if (index === 1 || index === 3) {
      firstSlideItems.push(item)
      firstSlideItems.push({ type: 'br' })
    } else {
      firstSlideItems.push(item)
    }
  })

  const secondSlideItems = []
  renderSecondSlide.forEach((item, index) => {
    if (index === 1 || index === 3) {
      secondSlideItems.push(item)
      secondSlideItems.push({ type: 'br' })
    } else {
      secondSlideItems.push(item)
    }
  })

  // 每2个标签添加 '|' ui 展示逻辑
  const allSlideItems = []

  const exposeSwitchValue = []

  firstSlideItems.forEach((item, index) => {
    if (item.type !== 'br' && firstSlideItems[index + ONE_LINE_LABEL_NUM]?.type === 'br') {
      allSlideItems.push(item)
      allSlideItems.push({ type: 'line' })
    } else {
      allSlideItems.push(item)
    }

    exposeSwitchValue.push(true)
  })

  secondSlideItems.forEach((item, index) => {
    if (item.type !== 'br' && secondSlideItems[index + ONE_LINE_LABEL_NUM]?.type === 'br') {
      allSlideItems.push(item)
      allSlideItems.push({ type: 'line' })
    } else {
      allSlideItems.push(item)
    }
    
    exposeSwitchValue.push(false)
  })

  const swiperLabelListFilted = swiperLabelList.value.map(item => item.type === 'gap' ? undefined : item).filter(Boolean)

  if (swiperLabelListFilted.length > ONE_LINE_LABEL_NUM || renderSecondSlide.length > 0) {
    displayMode.value = 'swiper'
  }

  // 第一行轮播的埋点，设置在goods_list，跟随商品曝光发出
  if (renderFirstSlide) {
    let firstSlideAna = renderFirstSlide.map((item) => {
      if (item.type === 'slot') {
        return `list_sorting_tag_${item.tagId || '-'}|ranking_list_module=ranking_list_label|${getRankingAna(rankingList.value, goodsIndex, true)}`
      } else if (item.textData?.tagId) {
        return `list_sorting_tag_${item.textData.tagId}`
      } else if (item.textData?.labelId) {
        return `list_sorting_tag_${item.textData.labelId}`
      } else if (item.starData?.tagId) {
        return `list_sorting_tag_${item.starData?.tagId}`
      }
    }).filter(Boolean)

    setAttrForAnalysis((el) => {
      el.setAttribute('data-horse-race-first', firstSlideAna.join('|'))
    })
  }

  swiperLabelList.value = allSlideItems 
  exposeSwitch.value = exposeSwitchValue

  isMounted.value = true
}

const setRealLeftStockAna = (swiperLabelList) => {
  const realLeftStockInfo = swiperLabelList.value.find(item => item.otherMarkAna?.includes('localMall'))
  if (realLeftStockInfo) {
    setAttrForAnalysis((el) => {
      el.setAttribute('data-real-left-stock', realLeftStockInfo.otherMarkAna || '')
    })
  }
}

onMounted(() => {
  // 延迟计算一方面减少有利于首屏
  // 另一方面 sui-drawer 弹窗内展示需要延迟计算，避免dom还没有渲染导致计算错误
  setTimeout(() => {
    switchDisplayMode()

    if(displayMode.value === 'swiper'){
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            isInViewPort.value = true
          } else {
            isInViewPort.value = false
          }
        })
      })
      observer.observe(horseRaceLabelRef.value) 
    }
  }, 500)
})

watch(
  () => goodsInfo.value.goods_sn,
  () => {
    const { pretreatInfo = {} } = goodsInfo.value ?? {}
    const horseRaceLabel = pretreatInfo?.horseRaceLabel ?? []
    const labelLoopingData = handelLabelData()

    // 有榜单加入轮播
    if (rankingList.value) {

      // 赛马有榜单标签 则加入赛马轮播，没有则榜单优先级最高
      let horseRaceLabelRankingIndex = (horseRaceLabel ?? []).findIndex(v => v.rankingList?.tagId)

      if (horseRaceLabelRankingIndex > -1) { // 赛马配置了榜单
        horseRaceLabelRankingIndex = horseRaceLabel.findIndex(v => v.rankingList?.tagId && v.rankingList?.isTypeMatch)
        if (horseRaceLabelRankingIndex === -1) { // 虽然赛马配置了榜单，但是榜单类型没匹配上
          return
        }
        const rankInfo = horseRaceLabel[horseRaceLabelRankingIndex]?.rankingList || {}

        labelLoopingData.swiperLabelList?.splice(horseRaceLabelRankingIndex, 0, {
          type: 'slot',
          random: Math.random(),
          labelIndex: horseRaceLabelRankingIndex,
          tagId: rankInfo.tagId,
        })
      } else {
        // 榜单标签优先级最高 单独展示一行
        labelLoopingData.swiperLabelList.unshift({
          type: 'slot',
          random: Math.random(),
          labelIndex: 0
        })
      }

      swiperLabelList.value = labelLoopingData.swiperLabelList.slice(0, MAX_LABEL_NUM)
      exposeSwitch.value = new Array(swiperLabelList.value.length).fill(false)

      setRealLeftStockAna(swiperLabelList)

      displayMode.value = 'singleRow'
      needToDisplay.value = true
      return
    }

    if (labelLoopingData.swiperLabelList.length === 0) {
      return
    }

    // 初始化渲染时添加一条特殊的线: 针对第2个标签
    swiperLabelList.value = [labelLoopingData.swiperLabelList[0], { type: 'gap' }, ...labelLoopingData.swiperLabelList.slice(1)]
    displayMode.value = 'singleRow'
    needToDisplay.value = true
    setRealLeftStockAna(swiperLabelList)
  },
  {
    immediate: true
  }
)

const handleLoopPlayStart = ({ index }) => {
  // 首轮轮播上报埋点 除第一行外的上报
  if (!config.showHorseRaceLabel || index === 0) {
    return
  }

  const slideItemRef = swiperItemRef.value?.[index]
  if (slideItemRef) {
    const slideItemRefEl = slideItemRef.$el
    const sellingPointsLabels = slideItemRefEl?.querySelectorAll('.product-card__auto-wrap > div:not(.placeholder)') || []
    const labelWrap = slideItemRefEl?.querySelector('.product-card__auto-wrap')

    let exposeHorseRaceLabelData = {
      targets: [],
      goods_id: item.value?.goods_id,
      rankingListAna: null,
    }

    Array.from(sellingPointsLabels).forEach((label) => {
      if (!label) return

      const offsetTop = getRelativeOffsetTop(label, labelWrap)
      const labelHeight = getLabelHeight(label) * index
      if (offsetTop === labelHeight) {
        exposeHorseRaceLabelData.targets.push(label)

        /* 榜单需要把榜单数据传进去 */
        if (label.getAttribute('data-type') === 'ranking') {
          exposeHorseRaceLabelData.rankingListAna = getRankingAna(rankingList.value, goodsIndex, false)
        }
      }

    })
    if (!exposeSwitch.value[index] && isInViewPort.value) {
      exposeSwitch.value[index] = true
      exposeHorseRaceLabelData.targets.length > 0 && exposeHorseRaceLabel(exposeHorseRaceLabelData)
    }
  }
}



</script>


<style lang="less" scoped>
.product-card__horse-race-swiper {
  --swiper-container-height: 16px;
  height: var(--swiper-container-height, 16px);
}
.product-card__horse-race-container {
  .flexbox();
  .align-center();
  height: 16px;
  
  margin-top: 4px;
  overflow: hidden; // 隐藏每个标签默认都加上的 '|' 线
}
</style>
