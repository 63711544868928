<template>
  <section
    class="S-product-card__wrapper-bottom"
    :class="{'S-product-card__wrapper-similar': showSimilarPopup}"
  >
    <!-- 图片底部slot -->
    <slot name="imgBottom"></slot>

    <!-- 主图右下角 -->
    <ProductCardImgRightBottom
      :belt-label="beltPriceCtrl.beltLabel"
      :related-color="relatedColor"
      :get-corner-property-upsell="getCornerPropertyUpsell"
      :sale-attr-label="saleAttrLabel"
    />

    <!-- 低价爆品 > 剩余库存 例子：only xx left > coming soon > 售罄 -->
    <template
      v-for="(infoItem, idx) in labelInfo"
      :key="idx"
    >
      <ImgBottomLabel
        :type="infoItem.type"
        :show-similar-popup="showSimilarPopup"
        :center="infoItem.center"
        :label-icon="infoItem.icon"
        :label-text="infoItem.text"
        :line-camp="infoItem.lineCamp"
      />
    </template>

    <!-- 腰带 -->
    <ImgBottomBeltLabel 
      v-if="beltPriceCtrl.beltLabel"
      :belt-label="beltPriceCtrl.beltLabel"
    />

    <!-- 例如: This Item -->
    <!-- 图片底部slot(盖在腰带之上) -->
    <slot name="imgBottomInTop"></slot>
  </section>
</template>

<script>
import ImgBottomLabel from './ImgBottomLabel.vue'
import ImgBottomBeltLabel from './ImgBottomBeltLabel.vue'
import ProductCardImgRightBottom from '../ProductCardImgRightBottom/index.js'

export default {
  name: 'ProductCardImgBottom',
  components: {
    ImgBottomLabel,
    ImgBottomBeltLabel,
    ProductCardImgRightBottom
  },
}
</script>

<script setup>

import { inject, computed, defineProps } from 'vue'

import { views } from 'public/src/pages/components/productItemV3/utils/constant.js'

const goodsInfo = inject('goodsInfo')
const labelsFromKey = inject('labelsFromKey') || ''
const language = inject('language') || {}
const config = inject('config') || {}

const props = defineProps({
  locateLabelsCtrl: {
    type: Object,
    default: () => ({
      needShowOldLabel: false,
      upperLeftVisible: false,
      upperRightVisible: false,
      lowerLeftVisible: false,
      lowerRightVisible: false,
    }),
  },
  imgBottomLeft: {
    type: [Object, null],
    default: null,
  },
  saleout: {
    type: [Object, null],
    default: null,
  },
  showSimilarPopup: {
    type: Boolean,
    default: false,
  },
  beltPriceCtrl: {
    type: Object,
    default: () => ({}),
  },
  imgDesignWidth: {
    type: [String, Number],
    default: 0,
  }
})

const relatedColor = computed(() => {
  const { materialValueKey, relatedColor, relatedColorCount } = goodsInfo.value?.[labelsFromKey]?.locateLabels?.LOWER_RIGHT_LABEL || {}

  return materialValueKey === 'color_information' 
    ? {
      data: relatedColor || [],
      count: relatedColorCount
    } 
    : {}
})
const getCornerPropertyUpsell = computed(() => {
  const { cornerPropertyUpsell } = goodsInfo.value?.[labelsFromKey]?.locateLabels?.LOWER_RIGHT_LABEL || {}

  return cornerPropertyUpsell
})
const comingSoon = computed(() => {
  return +goodsInfo.value?.new_product_unsale === 1
})
const stockLeft = computed(() => {
  return goodsInfo.value?.pretreatInfo?.stockLeft
})
// shein 库存逻辑
const showStockLeft = computed(() => {
  return stockLeft.value && config.showLeftStockShein
})
const saleOutText = computed(() => {
  // 非单列商卡显示腰带售罄样式
  const notSingleRowCard = views.LIST_PC_BROADWISE_VIEW !== labelsFromKey
  return notSingleRowCard && props.saleout?.show && props.saleout?.message
})
const labelInfo = computed(() => {
  const showBestDeal = props.beltPriceCtrl.showBestDeal
  const followLabelVisible = props.beltPriceCtrl.followLabelVisible
  const beltInfo = {
    type: ['orange', '12PX'],
    icon: 'sh_pc_sui_icon_lowprice_12px_fff',
    text: showBestDeal ? language.SHEIN_KEY_PC_25736 : language.SHEIN_KEY_PC_27061,
    lineCamp: showBestDeal ? 2 : 1,
    center: true
  }
  const stockLeftInfo = {
    type: ['black', '16PX'],
    text: stockLeft.value,
    center: true
  }
  // 保留款 > 剩余库存 > coming soon > 售罄
  // 保留款 > 跟价款
  if (showBestDeal) {
    return [beltInfo]
  }
  if (followLabelVisible && showStockLeft.value) {
    return [stockLeftInfo, beltInfo]
  }
  if (followLabelVisible) {
    return [beltInfo]
  }
  if (showStockLeft.value) {
    return [stockLeftInfo]
  } else if (config?.specialSoldOutLabel ?  saleOutText.value && props.showSimilarPopup : saleOutText.value) {
    const info = {
      type: ['black', '16PX'],
      text: saleOutText.value,
      center: true
    }
    if (props.showSimilarPopup) {
      info.icon = 'sh_pc_sui_icon_soldout_36px_fff'
    }
    if (comingSoon.value) {
      info.text = language.SHEIN_KEY_PC_17424
    }

    return [info]
  }

  return []
})

const saleAttrLabel = computed(() => {
  return goodsInfo.value?.pretreatInfo?.saleAttr?.label ?? ''
})
</script>

<style lang="less">
.S-product-card {
  &__wrapper-similar {
    top: 0;
  }
  &__wrapper-bottom {
    position: absolute;
    bottom: 0;
    .left(0);
    .right(0);
    .wrapper-bottom {
      &__series {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
      }

      &__badge {
        display: inline-block;
        min-width: 48px;
        line-height: 22px;
        padding: 0 5px;
        .margin-l(6px);
        margin-bottom: 6px;
        text-align: center;
        font-size: 12px;
      }

      &__line {
        width: 100%;
        background: rgba(153, 153, 153, 0.6);
        text-align: center;
        color: #fff;
        font-size: 12px;

        /*sh:begin*/
        height: 22px;
        line-height: 22px;
        /*rw:begin*/
        height: 25px;
        line-height: 25px;
        background: rgba(255, 255, 255, 0.6);
        color: #333;

        & > img {
          max-width: 100%;
          max-height: 100%;
          vertical-align: baseline;
        }
      }
    }
  }
}
</style>
