<template>
  <p 
    class="product-card__label-text"
    :class="textClass"
    :style="textStyle"
  >
    <img
      v-if="textData.icon"
      class="product-card__label-text-icon"
      :src="textData.icon"
    />
    <i 
      v-else-if="textData.suiIcon"
      :class="{
        'sh_pc_sui_icon': true, 
        [textData.suiIcon]: textData.suiIcon
      }"
    ></i> 

    <span class="product-card__label-text-content">{{ textData.text }}</span>
  </p>
</template>

<script name="ProductCardLabelsText" setup>
import { computed, toRefs } from 'vue'
// <!-- 文本类型组件 -->

// 包含以下类型
// 快速发货
// 本地发货
// 促销活动类  buy more save more、free gift
// 通用角标类型（优选卖家等等）

// eslint-disable-next-line vue/no-setup-props-destructure
const props = defineProps({
  textData: {
    type: Object,
    default() {
      return {
        className: '',
        style: '',
        text: ''
      }
    }
  },
  width: {
    type: String,
    default: ''
  },
  ellipsis: {
    type: Boolean,
    default: false
  }
})

const { textData, width, ellipsis } = toRefs(props)

const textStyle = computed(() => {
  let style = textData.value.style ?? {}

  if (width.value) {
    style = Object.assign({
      width: width.value
    }, style)
  }

  return style
})

const textClass = computed(() => {
  const className = []

  if (textData.value.className) {
    className.push(`tag-${textData.value.className}`)
  }
  if (ellipsis.value) {
    className.push('text-ellipsis')
  }
  if (textData.value.useItalic) {
    className.push('text-italic')
  }


  return className
})

</script>

<style lang="less" scoped>

.product-card__label-text {
  display: flex;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  white-space: nowrap;
  vertical-align: middle;

  color: @sui_color_gray_dark2;
  // 清除间距
  font-size: 12px;

  &.text-ellipsis {
    .product-card__label-text-content {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.text-italic {
    font-style: italic;
  }

  &.tag-QuickShip {
    background-color: var(--sui_color_ship_bg, #ECFCF3);
  }
  &.tag-QuickShipNew {
    border-radius: 2px;
    border: 1px solid rgba(25, 128, 85, 0.20);
    background: #ECFCF3;
    color: #198055;
  }
  &.tag-Seller {
    background-color: var(--sui_color_Preferred_bg, #FFF5E7);
  }
  &.tag-Activity {
    background-color: var(--sui_color_sale_bg, #FFF3F1);
  } 
  &.tag-qualityShop {
    padding-left: 0;
    color: var(---sui_color_main, #222);
    font-size: 14px;
    
    /* stylelint-disable-next-line selector-class-pattern */
    .sh_pc_sui_icon{
      margin-right: 2px;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .sh_pc_sui_icon {
    margin-right: 4px;
  }
}
.product-card__label-text-content {
  .text-overflow();
  flex: 1;
  min-width: 0;
}
.product-card__label-text-icon {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}
</style>
