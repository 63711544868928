<template>
  <img
    v-if="showMaskLayer"
    src=""
    :data-src="maskImg"
    :data-design-width="imgDesignWidth"
    class="lazyload product-item__mask-layer"
  />
</template>

<script setup>
import { inject, ref } from 'vue'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => ({}),
  },
  mainImg: {
    type: String,
    default: ''
  },
  imgDesignWidth: {
    type: [String, Number],
    default: 0
  },
  // @example '1-1'
  cropRate: {
    type: String,
    default: ''
  }
})
const labelsFromKey = inject('labelsFromKey', () => {})
const setAttrForAnalysis = inject('setAttrForAnalysis', () => {})
const showMaskLayer = ref(false)
const maskLayer = props.goodsInfo[labelsFromKey]?.maskLayer ?? {}

const {
  maskLayerSrc: maskImg,
  maskLayerWidth: maskWidth,
  maskLayerHeight: maskHeight,
  goodsRange: maskTagInfo,
  appTraceInfo
} = maskLayer ?? {}

const isSquareSrc = () => {
  return props?.mainImg.includes('_square')
}

const containerRatio = () => {
  const fixedRatio = props?.cropRate || '3-4'
  if (fixedRatio === '3-4') {
    return 4 / 3
  }

  if (fixedRatio === '4-5') {
    return 5 / 4
  }

  if (fixedRatio === '5-6') {
    return 6 / 5
  }

  if (fixedRatio === '13-16') {
    return 16 / 13
  }

  if (fixedRatio === '1-1') {
    return 1 / 1
  }

  // 以下是对非固定容器宽高比 根据imgSrc地址确定的容器大小
  if (isSquareSrc()) {
    // 1 : 1
    return 1 / 1
  }

  // 3 : 4
  return 4 / 3
}
if (
  maskImg &&
  maskWidth &&
  maskHeight &&
  containerRatio() === maskHeight / maskWidth
) {

  setAttrForAnalysis(el => {
    if (appTraceInfo) {
      el.setAttribute('data-mask-layer', appTraceInfo)
    } else if (maskTagInfo?.tagId) {
      el.setAttribute('data-mask-layer', `show_mask_layer_${maskTagInfo?.tagId}`)
    }
  })

  showMaskLayer.value = true
}
</script>

<style lang="less" scoped>
.product-item {
  &__mask-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }
  img[src=''], img:not([src]) {
    opacity: 0;
  }
}
</style>
